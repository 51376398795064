<template>
    <div>
        <b-row align-h="center" align-v="center" class="mb-4">
            <b-col cols="12">
                <b-card>
                    <!-- <b-card-header>
                        <h4>Complete Survey</h4>
                    </b-card-header> -->
                    <b-card-text>
                        <b-row>
                            <b-col cols="12" v-if="state === 'show'" class="px-2">
                                <b-row v-for="(item, index) in survey.surveyCategories" :key="index">
                                    <b-col cols="12" class="text-center">
                                        <h4 class="text-left">{{item.description}}</h4>
                                        <!-- <img v-if="item.imageFile.base64Data !== null" :src="returnFileObject(item.imageFile)" alt="" 
                                        class="checklist-images" @click="openModalImage(item.imageFile)"> -->
                                    </b-col>
                                    <b-col cols="12">
                                        
                                        <b-row class="my-1 py-1" v-for="(q, qIndex) in item.surveyQuestions" :key="qIndex">
                                            <!-- <b-col cols="12"><label>{{q.answerType}}</label></b-col> -->
                                            <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Text'">
                                                <label>{{q.question}}</label>
                                                <b-form-input v-model="q.answer" ></b-form-input>
                                            </b-col>

                                            <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Number'">
                                                <label>{{q.question}}</label>
                                                <b-form-input v-model="q.answer" type="number"></b-form-input>
                                            </b-col>

                                            <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'YesNo'">
                                                <label>{{q.question}}</label>
                                                
                                                <b-form-radio-group v-model="q.answer">
                                                    <b-form-radio value="Yes">Yes</b-form-radio>
                                                    <b-form-radio value="No">No</b-form-radio>
                                                </b-form-radio-group>
                                            </b-col>

                                            <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Image'">
                                                <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                                                <b-form-file
                                                    class="mt-1"
                                                    accept="image/*"
                                                    v-model="q.imageFile"
                                                    @change="((e) => {
                                                        imageAnswerUploaded(q, e)
                                                    })"
                                                    placeholder="Choose a file..."
                                                    drop-placeholder="Drop file here..."
                                                    
                                                ></b-form-file>
                                                <!-- <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span> -->
                                                <p class="text-center" v-if="q.loading > 0">({{q.loading}}) Being uploaded</p>
                                                <b-badge v-for="(item, index) in q.answerImages" :key="index" variant="success" class="m-1">
                                                    {{item.documentName}}
                                                    <a @click="removeImageFromQuestion(q, item.documentId)"> <b-icon-x-circle ></b-icon-x-circle></a>
                                                </b-badge>
                                            </b-col>
                                            
                                            <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'LongText'">
                                                <label>{{q.question}}</label>
                                                <b-form-textarea
                                                    class="pt-2"
                                                    rows="2"
                                                    size="sm"
                                                    v-model="q.answer"
                                                ></b-form-textarea>
                                            </b-col>

                                            <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'DateTime'">
                                                <label>{{q.question}}</label>
                                                <b-form-datepicker v-model="checklistTypeId"></b-form-datepicker>
                                            </b-col>


                                            <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Date'">
                                                <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                                                <b-form-datepicker v-model="q.answer" @change="validateAnswer(q)"></b-form-datepicker>
                                                <!-- <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span> -->
                                            </b-col>

                                            <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'Time'">
                                                <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                                                <b-form-timepicker v-model="q.answer" locale="en" @input="validateAnswer(q)"></b-form-timepicker>
                                                <!-- <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span> -->
                                            </b-col>

                                            <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'SingleSelect'">
                                                <label>{{q.question}}</label>
                                                <b-form-select v-model="q.answer">
                                                    <b-form-select-option v-for="(item, index) in q.surveyAcceptableAnswers" :key="index" :value="item.checkListAcceptableAnswerId">{{item.acceptableAnswer}}</b-form-select-option>
                                                </b-form-select>
                                            </b-col>  
                                            
                                            <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'MultiSelect'">
                                                <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                                                <b-form-checkbox-group>
                                                    <b-form-checkbox class='multiselect-checkox' v-model="q.answer" @change="answerSelected(q)"
                                                    v-for="(item, index) in q.surveyAcceptableAnswers" :key="index" :value="item.acceptableAnswer" name="" unchecked-value=""
                                                
                                                    >
                                                    {{item.acceptableAnswer}}
                                                    </b-form-checkbox>
                                                </b-form-checkbox-group>
                                                <!-- <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span> -->
                                            </b-col>

                                            <b-col cols="12" class="m-0 p-0" v-if="q.answerType === 'MultiLevel'">
                                                <label>{{q.question}}  <span v-if="q.required" class="text-red">*</span></label>
                                                <b-form-select v-model="q.answer"  @change="answerSelected(q)">
                                                    <b-form-select-option v-for="(item, index) in q.surveyAcceptableAnswers" :key="index" :value="item.acceptableAnswer">{{item.acceptableAnswer}}</b-form-select-option>
                                                </b-form-select>
                                                <p v-if="q.answer !== null">Choose one of the following:</p>

                                                <b-form-select v-model="q.subAnswer" @change="answerSelected(q)" v-if="q.answer !== null">
                                                    <b-form-select-option v-for="(item, index) in optionAnswers(q)" :key="index" :value="item.acceptableAnswer">{{item.acceptableAnswer}}</b-form-select-option>
                                                </b-form-select>
                                                <!-- <span class="text-red font-weight-400 text-left" v-if="hasError(q)"> {{ getError(q) }} </span> -->
                                            </b-col>
                                        </b-row>

                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="12" v-if="state === 'completed'"  align-h="center" class="px-2">
                                <label>Thank you for completing our survey.</label>
                            </b-col>

                        </b-row>
                        <b-row align-h="center" class="mx-0 mb-0">
                            <b-button class="mt-3 ml-auto primary" @click="save()" size="sm" variant="primary" squared v-if="state !='completed'">
                                <b-spinner v-if="state === 'loading'" small></b-spinner>
                                Submit Survey
                            </b-button>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex'
export default {
    data: () => ({
        formErrors: [],
        state: 'initializing',
        checklistTypeId: null,
        survey: {
            surveyID: null,
            active: false,
            description: null,
            questionType: null,
            createdDate: null,
            surveyCategories: [],
        },
        request: null
    }),
    created(){
        this.setBreadcrumb([
            {
                text: 'Survey'
            }
        ]),
        this.$store.commit('setSurveyKey', this.$route.params.key),
        this.getSurveyByKey()
        .then((res) => {
            this.survey = res.data
            if (this.survey.surveyID == 0){
                this.state = 'completed'
            }
            else {
                console.log("this.survey",this.survey)
                for (let i = 0; i < this.survey.surveyCategories.length; i++) 
                {
                    const element = this.survey.surveyCategories[i];
                    for (let j = 0; j < element.surveyQuestions.length; j++) {
                        const questionElement = element.surveyQuestions[j];
                        questionElement.answer = null
                    }
                }
                this.state = 'show'
            }
        })
    },
    methods:{
        ...mapActions(['uploadSurveyAnswerImage']),
        ...mapActions(['getSurveyByKey', 'saveSurvey']),
        ...mapActions(['addErrorMessage', 'addWarningMessage', 'addSuccessMessage', 'clearMessage']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        
        imageAnswerUploaded(q,e){
            for (let i = 0; i < e.target.files.length; i++) {
                const element = e.target.files[i];
                if (element.size > 20000000) {
                        //console.log('Document to big to be uploaded')
                        this.errors.push({message: 'Image to big to be uploaded'})
                    return
                }
            }
            this.createBase64ImageFile(q, e)
            q.imageFile = null
        },

        createBase64ImageFile(q, fileList){
            for (let i = 0; i < fileList.target.files.length; i++) {
                const element = fileList.target.files[i];
                const reader = new FileReader()
                q.loading++

                reader.onload = (e) => {
                    let file = e.target.result
                    let request = {
                        documentName: element.name,
                        base64Data: file,
                        description: element.name
                    }

                    this.$store.commit('setUploadAnswerImageRequest', request)
                    this.uploadSurveyAnswerImage()
                    .then((res) =>{
                        this.state = 'loading'
                        // q.answerImages.push({
                        //     documentName: element.name,
                        //     documentId: res.data.result
                        // })
                        q.answer = res.data
                        q.loading--
                        this.state = 'show'
                    })
                    .catch((err) => {
                        console.log(err)
                        q.loading--
                        this.state = 'show'
                    })
                }

                reader.readAsDataURL(element)
            }

        },
        // filesUploaded(q, e){
        //     for (let i = 0; i < e.target.files.length; i++) {
        //         const element = e.target.files[i];
        //         if (element.size > 20000000) {
        //                 //console.log('Document to big to be uploaded')
        //                 this.errors.push({message: 'Document to big to be uploaded'})
        //             return
        //         }
        //     }
        //     this.createBase64File(q, e)
        //     q.imageFile = null
        // },
        // createBase64File(q, fileList){
            
        //     for (let i = 0; i < fileList.target.files.length; i++) {
        //         const element = fileList.target.files[i];
        //         const reader = new FileReader()
        //         q.loading++

        //         reader.onload = (e) => {
        //             let file = e.target.result
        //             let request = {
        //                 documentName: element.name,
        //                 base64Data: file,
        //                 description: element.name
        //             }
                    
        //             this.$store.commit('setUploadAnswerImageRequest', request)
        //             this.uploadSurveyAnswerImage()
        //             .then((res) =>{
        //                 this.state = 'loading'
        //                 q.answerImages.push({documentId: res.data.result})
        //                q.documentName = request.documentName
        //                 this.state = 'show'
        //                 q.loading--
        //             })
        //             .catch(err=>{
        //                 console.log("upload error",err)
        //                 q.loading--
        //             })
                    
        //         }

        //         reader.readAsDataURL(element)
        //     }

        // },
        
        save(){
            let request = { 
                surveyID: this.survey.surveyID,
                answers : []
            }

            let requestList = []
            let exceptionDetected = false

            this.survey.surveyCategories.forEach(element => {
                element.surveyQuestions.forEach(elemItem => {                    
                    if (elemItem.required === true && (elemItem.answer === null || elemItem.answer === '' || elemItem.answer === ' ')) {                        
                        this.addWarningMessage(`Please enter an answer for the question: ${elemItem.question}`)
                        exceptionDetected = true
                    }
                    if (elemItem.answer !== null && elemItem.answer !== '' && elemItem.answer !== ' ' && elemItem.answerType !== 'Image') {
                        if (elemItem.answer.length > 8000) {
                            this.addWarningMessage(`Please shorten the answer for the question: ${elemItem.question}`)
                            exceptionDetected = true
                        }
                    }
                    requestList.push({"questionID": elemItem.questionId, "answer": String(elemItem.answer)})
                });
            });

            request.answers = requestList;

            if(!exceptionDetected){
                this.state = 'loading'
                this.$store.commit('setSaveSurveyRequest', request)
                this.saveSurvey()
                .then((res) => {
                    //console.log(res)
                    if(res.data){
                        //this.addSuccessMessage("Survey successfully submitted.");
                        this.state = 'completed'
                        //this.$router.push({path: '/home'})
                    }else{
                        this.state = 'completed'
                        //this.addErrorMessage("Failed submitting survey.");
                    }
                })                
            }
        },

        answerSelected(question){
            this.state = 'loading'

            if (question.answerType === 'MultiSelect') {
                const items = question.answer.map(element => question.surveyAcceptableAnswers.find(answer => answer.acceptableAnswer === element && answer.isException === true)).filter(Boolean);
                question.isException = items.length !== 0
            } else if (question.answerType !== 'MultiLevel') {
                let item = question.surveyAcceptableAnswers.find(element => element.acceptableAnswer === question.answer)
                question.isException = item.isException
            } else if (question.subAnswer !== null) {
                let item = question.surveyAcceptableAnswers.find(element => element.acceptableAnswer === question.answer)
                let itemAnswer = item.multiLevelAcceptableAnswers.find(element => element.acceptableAnswer === question.subAnswer)
                
                if (itemAnswer !== undefined) question.isException = itemAnswer.isException
                else question.isException = false
            } else {
                question.isException = false
            }

            this.validateAnswer(question);
            this.state = 'show'
        },
        validateAnswer(changedQuestion) {
            const result = this.formErrors.find(q => q.questionId === changedQuestion.questionId);

            let error = null;

            if (changedQuestion.isException) {
                if (changedQuestion.answer && (!changedQuestion.answerNotes || changedQuestion.answerNotes == null)) {
                    error = `Please enter a note for the question: ${changedQuestion.question}`;

                    if (!result) {
                        this.formErrors.push({ questionId: changedQuestion.questionId, error:  error});
                    }
                }
            }

            if (changedQuestion.required && changedQuestion.answerType == 'MultiLevel') {
                if (!changedQuestion.subAnswer || changedQuestion.subAnswer == null) {
                    error = `Please enter an answer for the question: ${changedQuestion.question}`;
                    if (!result) {
                        this.formErrors.push({ questionId: changedQuestion.questionId, error:  error});
                    }
                } 

            } else if (changedQuestion.required && (!changedQuestion.answer || changedQuestion.answer == null)) {
                if (changedQuestion.answerType !== 'Image' 
                && changedQuestion.answer 
                && typeof changedQuestion.answer == 'string' 
                && changedQuestion.answer.length > 8000) {
                    error = `Please shorten the answer for the question: ${changedQuestion.question}`;
                } else if (changedQuestion.answerType === 'Image') {
                    if (changedQuestion.answerImages.length < 1) {
                        error = `Please enter an answer for the question: ${changedQuestion.question}`;
                    }
                } else {
                    error = `${changedQuestion.question} is required`;
                }

                if (!result) {
                    this.formErrors.push({ questionId: changedQuestion.questionId, error:  error});
                }
            }

            if (result) {
                result.error = error;
            }
        },
        validateTextAnswer(item){
            if (item.answer === null && item.required === true) {
                return false
            }

            if (item.surveyAcceptableAnswers.length > 0 && item.answer !== null) {
                let foundAnswer = false
                for (let i = 0; i < item.surveyAcceptableAnswers.length; i++) {
                    const element = item.surveyAcceptableAnswers[i];
                    if (item.answer === element.acceptableAnswer) {
                        foundAnswer = true
                    }
                }
                if (foundAnswer === false) {
                    return false
                }
            }
            return null
        },
    },
    computed:{
        ...mapState(['selectedSurvey'])
    }
}
</script>